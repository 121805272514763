import {
  NodeModel,
  NodeModelGenerics,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";
import { SparkyELTPortModel } from "../../port/SparkyELTPortModel";

export interface DiamondNodeModelGenerics {
  PORT: SparkyELTPortModel;
}

export class SortNodeModel extends NodeModel<
  NodeModelGenerics & DiamondNodeModelGenerics
> {
  inputColumns: string[];
  selectedColumns: string[];
  outputColumns: string[];
  comment: string;

  constructor() {
    super({
      type: "Node_SORT",
    });
    this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
    this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));
    this.inputColumns = [];
    this.selectedColumns = [];
    this.outputColumns = [];
    this.comment = "";
  }

  getSelectedColumns(): string[] {
    return this.selectedColumns;
  }

  setSelectedColumns(cols: string[]): void {
    this.selectedColumns = cols;
    this.setOutputColumns(cols);
  }

  getInputColumns(): string[] {
    return this.inputColumns;
  }

  setInputColumns(cols: string[]): void {
    this.inputColumns = cols;
  }

  getOutputColumns(): string[] {
    return this.outputColumns;
  }

  setOutputColumns(cols: string[]): void {
    this.outputColumns = cols;
  }

  getComment(): string {
    return this.comment;
  }

  setComment(comment: string): void {
    this.comment = comment;
  }

  serialize(): any {
    return {
      ...super.serialize(),
      selectedColumns: this.selectedColumns,
      outputColumns: this.outputColumns,
      comment: this.comment,
    };
  }

  deserialize(event: any): void {
    super.deserialize(event);
    this.selectedColumns = event.data.selectedColumns || [];
    this.outputColumns = event.data.outputColumns || [];
    this.comment = event.data.comment || "";
  }
}
