import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  FormControl,
  TextField,
  MenuItem,
} from "@mui/material";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import { SortNodeModel } from "../SortNodeModel";

interface SortNodeSettingsProps {
  open: boolean;
  handleClose: () => void;
  nodeModel: SortNodeModel;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const SortNodeSettings: React.FC<SortNodeSettingsProps> = ({
  open,
  handleClose,
  nodeModel,
  comment,
  setComment,
  setSettingsSummary,
}) => {
  const [dropdowns, setDropdowns] = useState<string[]>([]);

  useEffect(() => {
    setDropdowns([]);
    setComment(nodeModel.comment || "");
  }, [nodeModel, setComment, open]);

  const handleApply = () => {
    const payload = {
      type: "Node_SORT",
      selectedColumns: dropdowns,
      outputColumns: dropdowns,
      comment,
    };

    console.log("Payload to Backend:", payload);

    nodeModel.setSelectedColumns(dropdowns);
    nodeModel.setComment(comment);

    const summary = `Make Unique Columns: ${dropdowns.join(", ")}`;
    setSettingsSummary(summary);

    handleClose();
  };

  const handleAddDropdown = () => {
    setDropdowns([...dropdowns, ""]);
  };

  const handleRemoveDropdown = (index: number) => {
    const updatedDropdowns = dropdowns.filter((_, i) => i !== index);
    setDropdowns(updatedDropdowns);
  };

  const handleDropdownChange = (value: string, index: number) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = value;
    setDropdowns(updatedDropdowns);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { backgroundColor: "white" } }}
    >
      <DialogHeader
        headline={"Make Unique Node Settings"}
        closeDialog={handleClose}
      />
      <DialogContent>
        <Grid container spacing={2}>
          {dropdowns.map((dropdown, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <FormControl fullWidth>
                <TextField
                  select
                  label={`Make Unique Column ${index + 1}`}
                  value={dropdown}
                  onChange={(e) => handleDropdownChange(e.target.value, index)}
                >
                  {nodeModel.inputColumns.map((col, idx) => (
                    <MenuItem key={idx} value={col}>
                      {col}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <Button
                onClick={() => handleRemoveDropdown(index)}
                color="error"
                size="small"
              >
                Remove
              </Button>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button onClick={handleAddDropdown} variant="outlined">
              Add Make Unique Column
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Node Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                multiline
                rows={2}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleApply}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SortNodeSettings;
