import {
  NodeModel,
  NodeModelGenerics,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";
import { SparkyELTPortModel } from "../../port/SparkyELTPortModel";

export interface DiamondNodeModelGenerics {
  PORT: SparkyELTPortModel;
}

export class FilterNodeModel extends NodeModel<
  NodeModelGenerics & DiamondNodeModelGenerics
> {
  inputColumns: string[];
  outputColumnsTop: string[];
  outputColumnsBottom: string[];
  comment: string;
  filteredConditions: string[] = [];
  skippedConditions: string[] = [];

  constructor() {
    super({
      type: "Node_FILTER",
    });
    this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
    this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "outputTop"));
    this.addPort(
      new SparkyELTPortModel(PortModelAlignment.RIGHT, "outputBottom")
    );

    this.inputColumns = [];
    this.outputColumnsTop = [];
    this.outputColumnsBottom = [];
    this.comment = "";
  }

  setFilters(filteredConditions: string[], skippedConditions: string[]) {
    this.filteredConditions = filteredConditions;
    this.skippedConditions = skippedConditions;
    this.outputColumnsTop = [...this.inputColumns];
    this.outputColumnsBottom = [...this.inputColumns];
  }

  private evaluateCondition(
    value: any,
    operator: string,
    compareValue: any
  ): boolean {
    switch (operator) {
      case "=":
        // eslint-disable-next-line
        return value == compareValue;
      case "!=":
        // eslint-disable-next-line
        return value != compareValue;
      case ">":
        return value > compareValue;
      case "<":
        return value < compareValue;
      case ">=":
        return value >= compareValue;
      case "<=":
        return value <= compareValue;
      default:
        return false;
    }
  }

  applyFilters(inputData: any[]): {
    matchedRows: any[];
    filteredOutRows: any[];
  } {
    const matchedRows = inputData.filter((row) => {
      return this.filteredConditions.every((condition) => {
        const [column, operator, value] = condition.split(/ (<=|>=|=|<|>|!=) /);
        return this.evaluateCondition(row[column], operator, value);
      });
    });

    const filteredOutRows = inputData.filter((row) => {
      return (
        this.skippedConditions.some((condition) => {
          const [column, operator, value] =
            condition.split(/ (<=|>=|=|<|>|!=) /);
          return this.evaluateCondition(row[column], operator, value);
        }) || !matchedRows.includes(row)
      );
    });

    return { matchedRows, filteredOutRows };
  }

  getFilterResults(inputData: any[]): {
    outputTop: any[];
    outputBottom: any[];
  } {
    const { matchedRows, filteredOutRows } = this.applyFilters(inputData);
    return {
      outputTop: matchedRows,
      outputBottom: filteredOutRows,
    };
  }

  serialize(): any {
    return {
      ...super.serialize(),
      inputColumns: this.inputColumns,
      filteredConditions: this.filteredConditions,
      skippedConditions: this.skippedConditions,
      comment: this.comment,
    };
  }

  deserialize(event: any): void {
    super.deserialize(event);
    this.inputColumns = event.data.inputColumns || [];
    this.filteredConditions = event.data.filteredConditions || [];
    this.skippedConditions = event.data.skippedConditions || [];
    this.comment = event.data.comment || "";
    this.outputColumnsTop = [...this.inputColumns];
    this.outputColumnsBottom = [...this.inputColumns];
  }

  getInputColumns(): string[] {
    return this.inputColumns;
  }

  setInputColumns(cols: string[]): void {
    this.inputColumns = cols;
    this.outputColumnsTop = [...cols];
    this.outputColumnsBottom = [...cols];
  }

  getOutputColumnsTop(): string[] {
    return this.outputColumnsTop;
  }

  getOutputColumnsBottom(): string[] {
    return this.outputColumnsBottom;
  }

  getComment(): string {
    return this.comment;
  }

  setComment(comment: string): void {
    this.comment = comment;
  }

  getFilteredConditions(): string[] {
    return this.filteredConditions;
  }

  getSkippedConditions(): string[] {
    return this.skippedConditions;
  }
}
