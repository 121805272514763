import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  FormControl,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import { GroupByNodeModel } from "../GroupByNodeModel";

interface GroupByNodeSettingsProps {
  open: boolean;
  handleClose: () => void;
  nodeModel: GroupByNodeModel;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const GroupByNodeSettings: React.FC<GroupByNodeSettingsProps> = ({
  open,
  handleClose,
  nodeModel,
  comment,
  setComment,
  setSettingsSummary,
}) => {
  const [selectedColumn1, setSelectedColumn1] = useState<string>("");
  const [selectedColumn2, setSelectedColumn2] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [fieldName, setFieldName] = useState<string>("");
  const [delimiter, setDelimiter] = useState<string>("");

  const predefinedValues = [
    "Summe",
    "Anzahl",
    "Minimum",
    "Maximum",
    "Durchschnitt",
    "Konkatenieren",
  ];

  useEffect(() => {
    setSelectedColumn1("");
    setSelectedColumn2("");
    setSelectedValue("");
    setFieldName("");
    setDelimiter("");
    setComment(nodeModel.comment || "");
  }, [nodeModel, setComment, open]);

  const handleApply = () => {
    const payload = {
      type: "Node_GROUP_BY",
      selectedColumn1,
      selectedColumn2,
      selectedValue,
      fieldName,
      delimiter,
    };

    console.log("Payload to Backend:", payload);

    nodeModel.setGroupedByColumns([selectedColumn1]);
    nodeModel.setSelectedColumn2(selectedColumn2);
    nodeModel.setSelectedValue(selectedValue);
    nodeModel.setFieldName(fieldName);
    nodeModel.setDelimiter(delimiter);
    nodeModel.comment = comment;

    const summary = `Group By: ${selectedColumn1}, Operation: ${selectedValue}, Target Column: ${selectedColumn2}, New Column: ${fieldName}, Delimiter: ${delimiter}`;
    setSettingsSummary(summary);

    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { backgroundColor: "white" } }}
    >
      <DialogHeader
        headline={"Group By Node Settings"}
        closeDialog={handleClose}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                select
                label="Identifikator"
                value={selectedColumn1}
                onChange={(e) => setSelectedColumn1(e.target.value)}
              >
                {nodeModel.inputColumns.map((col, idx) => (
                  <MenuItem key={idx} value={col}>
                    {col}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                select
                label="Aktions-Spalte"
                value={selectedColumn2}
                onChange={(e) => setSelectedColumn2(e.target.value)}
              >
                {nodeModel.inputColumns.map((col, idx) => (
                  <MenuItem key={idx} value={col}>
                    {col}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                label="Aktion"
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                {predefinedValues.map((val, idx) => (
                  <MenuItem key={idx} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Neuer Spaltenname"
                value={fieldName}
                onChange={(e) => setFieldName(e.target.value)}
              />
            </FormControl>
          </Grid>

          {selectedValue === "Konkatenieren" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Trennzeichen"
                  value={delimiter}
                  onChange={(e) => setDelimiter(e.target.value)}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Node Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                multiline
                rows={2}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleApply}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupByNodeSettings;
