import FilterNodeWidget from "./FilterNodeWidget";
import { FilterNodeModel } from "./FilterNodeModel";
import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { GenerateModelEvent } from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import { GenerateWidgetEvent } from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";

export class FilterNodeFactory extends AbstractReactFactory<
  FilterNodeModel,
  DiagramEngine
> {
  constructor() {
    super("Node_FILTER");
  }

  generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
    return (
      <FilterNodeWidget
        engine={this.engine}
        width={303}
        height={179}
        node={event.model}
      />
    );
  }

  generateModel(event: GenerateModelEvent) {
    return new FilterNodeModel();
  }
}
