import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  List,
  FormControl,
  TextField,
  Grid,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import { Box } from "@mui/system";
import { FilterNodeModel } from "../FilterNodeModel";

interface FilterNodeSettingsProps {
  open: boolean;
  handleClose: () => void;
  nodeModel: FilterNodeModel;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const FilterNodeSettings: React.FC<FilterNodeSettingsProps> = (
  props: FilterNodeSettingsProps
) => {
  const dialogContentRef = React.useRef<HTMLDivElement>(null);

  const [inputCols, setInputCols] = useState<string[]>([]);
  const [groups, setGroups] = useState<
    { conditions: { column: string; operator: string; value: string }[] }[]
  >([{ conditions: [] }]);
  const operators = ["", "=", ">", "<", "!=", ">=", "<="];

  const addCondition = (groupIndex: number) => {
    const newGroups = [...groups];
    newGroups[groupIndex].conditions.push({
      column: "",
      operator: "",
      value: "",
    });
    setGroups(newGroups);
  };

  const removeCondition = (groupIndex: number, conditionIndex: number) => {
    const newGroups = [...groups];
    newGroups[groupIndex].conditions.splice(conditionIndex, 1);
    setGroups(newGroups);
  };

  const updateCondition = (
    groupIndex: number,
    conditionIndex: number,
    key: "column" | "operator" | "value",
    value: string
  ) => {
    const newGroups = [...groups];
    newGroups[groupIndex].conditions[conditionIndex][key] = value;
    setGroups(newGroups);
  };

  const addGroup = () => {
    setGroups([...groups, { conditions: [] }]);
  };

  const removeGroup = (groupIndex: number) => {
    const newGroups = [...groups];
    newGroups.splice(groupIndex, 1);
    setGroups(newGroups);
  };

  const onApply = () => {
    const formattedConditions = groups
      .map((group) =>
        group.conditions
          .filter(
            (condition) =>
              condition.column && condition.operator && condition.value
          )
          .map(
            (condition) =>
              `\`${condition.column}\` ${condition.operator} ${
                isNaN(Number(condition.value))
                  ? `'${condition.value}'`
                  : condition.value
              }`
          )
      )
      .filter((groupConditions) => groupConditions.length > 0)
      .map((groupConditions) => `(${groupConditions.join(" AND ")})`)
      .join(" OR ");

    props.nodeModel.setFilters([formattedConditions], []);
    props.setSettingsSummary(formattedConditions || "No filters applied");
    props.nodeModel.setComment(props.comment);
    onClose();
  };

  const onClose = () => {
    props.handleClose && props.handleClose();
  };

  useEffect(() => {
    if (!props.open) return;
    const dialogContent = dialogContentRef.current;
    const preventScroll = (e: WheelEvent) => e.stopPropagation();
    if (props.open)
      setTimeout(
        () => dialogContent?.addEventListener("wheel", preventScroll),
        0
      );
    return () => dialogContent?.removeEventListener("wheel", preventScroll);
  }, [props.open, dialogContentRef]);

  useEffect(() => {
    setInputCols(props.nodeModel.inputColumns);
  }, [props.nodeModel.inputColumns]);

  useEffect(() => {
    props.setComment(props.nodeModel.comment);
    // eslint-disable-next-line
  }, [props.nodeModel.comment]);

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      PaperProps={{ sx: { backgroundColor: "white" } }}
      disableEnforceFocus
      fullWidth
    >
      <DialogHeader headline={"Filter Rows"} closeDialog={onClose} />
      <DialogContent ref={dialogContentRef}>
        <List>
          {groups.map((group, groupIndex) => (
            <Box
              key={groupIndex}
              sx={{ marginBottom: 2, border: "1px solid gray", padding: 2 }}
            >
              <strong>
                Group {groupIndex + 1} (AND conditions):{" "}
                <IconButton
                  onClick={() => removeGroup(groupIndex)}
                  size="small"
                  color="error"
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </strong>
              {group.conditions.map((condition, conditionIndex) => (
                <Box
                  key={conditionIndex}
                  sx={{ display: "flex", alignItems: "center", marginY: 1 }}
                >
                  <FormControl sx={{ minWidth: 120, marginRight: 1 }}>
                    <Select
                      value={condition.column}
                      onChange={(e) =>
                        updateCondition(
                          groupIndex,
                          conditionIndex,
                          "column",
                          e.target.value
                        )
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Column
                      </MenuItem>
                      {inputCols.map((col) => (
                        <MenuItem key={col} value={col}>
                          {col}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: 80, marginRight: 1 }}>
                    <Select
                      value={condition.operator}
                      onChange={(e) =>
                        updateCondition(
                          groupIndex,
                          conditionIndex,
                          "operator",
                          e.target.value
                        )
                      }
                      displayEmpty
                    >
                      {operators.map((op) => (
                        <MenuItem key={op} value={op}>
                          {op || "No Operator"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    placeholder="Value"
                    value={condition.value}
                    onChange={(e) =>
                      updateCondition(
                        groupIndex,
                        conditionIndex,
                        "value",
                        e.target.value
                      )
                    }
                  />
                  <IconButton
                    onClick={() => removeCondition(groupIndex, conditionIndex)}
                    size="small"
                    color="error"
                    sx={{ marginLeft: 1 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={() => addCondition(groupIndex)}>
                + Add AND Condition
              </Button>
            </Box>
          ))}
        </List>
        <Button onClick={addGroup}>+ Add OR Group</Button>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Node Comment"
              value={props.comment}
              onChange={(e) => props.setComment(e.target.value)}
              multiline
              rows={2}
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onApply}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterNodeSettings;
