import {
  NodeModel,
  NodeModelGenerics,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";
import { SparkyELTPortModel } from "../../port/SparkyELTPortModel";

export interface DiamondNodeModelGenerics {
  PORT: SparkyELTPortModel;
}

export class GroupByNodeModel extends NodeModel<
  NodeModelGenerics & DiamondNodeModelGenerics
> {
  inputColumns: string[];
  outputColumns: string[];
  groupedByColumns: string[];
  selectedColumn2: string;
  selectedValue: string;
  fieldName: string;
  comment: string;
  delimiter: string;

  constructor() {
    super({
      type: "Node_GROUP_BY",
    });
    this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
    this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));
    this.inputColumns = [];
    this.outputColumns = [];
    this.groupedByColumns = [];
    this.selectedColumn2 = "";
    this.selectedValue = "";
    this.fieldName = "";
    this.comment = "";
    this.delimiter = "";
  }

  getInputColumns(): string[] {
    return this.inputColumns;
  }

  setInputColumns(cols: string[]): void {
    this.inputColumns = cols;
  }

  getOutputColumns(): string[] {
    return this.outputColumns;
  }

  setOutputColumns(cols: string[]): void {
    this.outputColumns = cols;
  }

  getComment(): string {
    return this.comment;
  }

  setComment(comment: string): void {
    this.comment = comment;
  }

  setGroupedByColumns(cols: string[]): void {
    this.groupedByColumns = cols;
  }

  setSelectedColumn2(column: string): void {
    this.selectedColumn2 = column;
  }

  setSelectedValue(value: string): void {
    this.selectedValue = value;
  }

  setFieldName(name: string): void {
    this.fieldName = name;
  }

  setDelimiter(delimiter: string): void {
    this.delimiter = delimiter;
  }

  serialize(): any {
    return {
      ...super.serialize(),
      groupedByColumns: this.groupedByColumns,
      selectedColumn2: this.selectedColumn2,
      selectedValue: this.selectedValue,
      fieldName: this.fieldName,
      comment: this.comment,
      delimiter: this.delimiter,
    };
  }

  deserialize(event: any): void {
    super.deserialize(event);
    this.groupedByColumns = event.data.groupedByColumns || [];
    this.selectedColumn2 = event.data.selectedColumn2 || "";
    this.selectedValue = event.data.selectedValue || "";
    this.fieldName = event.data.fieldName || "";
    this.comment = event.data.comment || "";
    this.delimiter = event.data.delimiter || "";
  }
}
